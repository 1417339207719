<template>
    <div>
        <el-dialog
            title="添加储值卡"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="套餐名称" prop="name">
                    <el-input v-model="form.name" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="项目" prop="incomeDistriId">
                    <le-select-remote-search 
                        class="projectBox"
                        style="padding:0" 
                        v-model="form.incomeDistriId" 
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目(可搜索)" />
                </el-form-item>
                <el-form-item label="站点" prop="statioinIdList">
                    <le-select-remote-search 
                        class="projectBox"
                        style="padding:0" 
                        :multiple="true" 
                        v-model="form.statioinIdList" 
                        :options="optionsStatioinIdList" 
                        placeholder="请选择站点(可搜索)" />
                </el-form-item>
                <el-form-item label="有效期（天）" prop="validity">
                    <jd-input-intnum maxlength="6" v-model="form.validity"></jd-input-intnum>
                    <!-- <el-input maxlength="6" v-model="form.validity" @input="form.validity=form.validity.replace(/^\.+|[^\d]+/g,'')"></el-input> -->
                    <span class="a-c-error">*有限期填写-1则不限制。</span>
                </el-form-item>
                <el-form-item label="金额" prop="promotionPrice">
                    <div class="a-flex-rfsc">
                        <jd-input-price v-model="form.promotionPrice"></jd-input-price>
                        <jd-md-price :number='form.promotionPrice*100'></jd-md-price>
                    </div>
                </el-form-item>
                <el-form-item label="赠送(毛豆)" prop="giftAmount">
                    <el-input maxlength="6" v-model="form.giftAmount" @input="form.giftAmount=form.giftAmount.replace(/^\.+|[^\d]+/g,'')"></el-input>
                </el-form-item>
                <p class="a-c-error">*当前毛豆兑换比例为1:100，即￥1等于100毛豆。</p>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex';
    export default {
        data() {
            return {
                dialogVisible: false,
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsStatioinIdList: {
                    url: this.$Config.apiUrl.monthStationList,
                    method: "post",
                    params: {
                        incomeDistriId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//站点数据
                form: {
                    id: '',
                    name: "",//名称(月卡/季卡/年卡/时长卡/次卡)
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/czv.png',//头像
                    type: '5',//月卡类型 1:充电卡 2:停车卡 3:停车充电卡
                    isTempCard: '6',//是否是临时卡 1:是 0:否
                    isSystemDefaultCard: '0',//是否是系统默认卡 1:是 0:否
                    incomeDistriId: '',//项目ID 月卡配置必须与项目挂钩
                    validity: -1,//有效期(天) -1不限制
                    price: '',//原价(划线价,对比价,单位:分)
                    promotionPrice: '',//促销价(实际支付价格,单位:分)
                    promotionDescription: "毛豆储值卡",//促销描述
                    quotaTimes: '0',//充电次数限制
                    quotaDuration: '0',//充电时长限制(单位:分)
                    parkTimes: '0',//停车限制次数 0:不限制
                    applicableDescription: "毛豆储值卡",//适用范围描述
                    activationMode: 0,//激活模式(0:立即激活,1:首次使用激活)
                    showMode: 1,//1:全部可见 2:充电可见 3:停车可见
                    cardNeedPost: '0',//卡片是否需要邮寄 1:需要 0:不需要
                    cardPrice: 0,//实体卡费用
                    enableTime: dayjs(),//开售时间
                    expireTime: dayjs().add(20, 'year'),//失效时间
                    quotaTimeType: '',//充电时间类型
                    parkTimeType: '',//停车时间限制
                    quotaLimitType: '',// 充电次数限制类型
                    parkLimitType: '',// 开门次数限制类型
                    bikeTypeIdList: [],//
                    statioinIdList: [],//
                    giftAmount: '',//储值卡充值赠送金额
                    activity: null,//关联活动
                    activityList: null,//关联活动
                    payWay: '1',
                    maxPrice: '',//临停后付金额上限
                    chargingExpireType: '1',
                },
                rules: {
                    name: [{required: true, message:'请输入名称', trigger: 'blur'}],
                    incomeDistriId: [{required: true, message:'请选择项目', trigger: 'change'}],
                    statioinIdList: [{ type: 'array', required: true, message: '请至少选择一个站点', trigger: 'change' }],
                    promotionPrice: [{required: true, message:'请输入金额', trigger: 'blur'}],
                }
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
            'form.incomeDistriId' :{
                handler (val) {
                    this.optionsStatioinIdList.params.incomeDistriId = val
                    this.form.statioinIdList = []
                }
            }
        },
        computed: {
            ...mapState(["company"]),
        },
        methods:{
            submit () {
                if(this.company.company.companyType == 2 && this.form.promotionPrice > 200) {
                    this.$message.error('毛豆储值卡最大额度为200元')
                    return false
                }
                if(this.company.company.companyType == 2 && this.form.giftAmount / 100 / this.form.promotionPrice > 0.25) {
                    this.$message.error('毛豆储值卡赠送金额不得超过售价的25%')
                    return false
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认添加？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            let reqData = {
                                ...this.form
                            }
                            reqData.promotionPrice = reqData.promotionPrice?Number((reqData.promotionPrice*100).toFixed(0)):0
                            reqData.statioinIdList = reqData.statioinIdList.map(item=>{
                                return {
                                    code: item,
                                    name: ''
                                }
                            })
                            this.$Axios._post({
                                url: this.$Config.apiUrl.addMonthCard,
                                method: "post",
                                params: {
                                    validList: [reqData]
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$emit('success');
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>

<style lang='scss' scoped>
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
        .el-input__inner{
            width: 100%;
        }
    }
</style>